import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import {Link} from 'gatsby';


export default function BreadCrumb({title,breadcrumbsArray}: any) {
  return (
    <div role="presentation">
      <Breadcrumbs aria-label="breadcrumb">
        <Typography sx={{color: (theme:any) => (theme.palette.primary.main)}} style={{textDecoration: "none"}} variant="subtitle1" component={Link} to={"/"}>
            Home
        </Typography>
        {breadcrumbsArray.map((breadcrumb: any) => (
          <Typography
          sx={{color: (theme:any) => (theme.palette.primary.main)}}
          style={{textDecoration: "none"}}
          variant="subtitle1"
          component={Link}
          to={breadcrumb.slug}
          key={breadcrumb.slug}
          >
            {breadcrumb.title}
          </Typography>
        ))}
        <Typography variant='subtitle1'>
            {title}
        </Typography>
      </Breadcrumbs>
    </div>
  );
}