import React from "react";
import { styled } from "@mui/material/styles";
import { Autoplay, Mousewheel, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';
import "swiper/css/navigation";


interface Quote {
  quote: string;
  author: string;
}

interface Props {
  quotes: Quote[];
}

const Quote = styled("div")(({ theme }) => ({
  fontStyle: "italic",
  textAlign: "center",
  padding: theme.spacing(4),
  maxWidth: "1200px",
  fontSize: theme.typography.h3.fontSize,
  color: theme.palette.primary.main,
  lineHeight: 1.2,
}));

const Author = styled("div")(({ theme }) => ({
  fontWeight: "bold",
  textAlign: "center",
  marginTop: theme.spacing(2),
  color: theme.palette.text.secondary,
}));

const SwiperWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
//   minHeight: "400px", // set a minimum height
  width: "100%",
  ".swiper-slide": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    // height: "100%",
  },
  // Style the navigation arrows
  ".swiper-button-prev, .swiper-button-next": {
    color: "rgb(82 82 82)",
    width: "50px",
    height: "50px",
    background: "transparent",
    zIndex: 1,
    "&:after": {
      fontSize: "30px",
    },
    "&:hover": {
      background: "transparent",
    },
    "@media (max-width: 768px)": {
      width: "30px",
      height: "30px",
      "&:after": {
        fontSize: "20px",
      },
    },
  },
  ".swiper-button-prev": {
    left: "10px",
  },
  ".swiper-button-next": {
    right: "10px",
  },
}));

const Container = styled("div")({
  position: "relative",
});

const QuoteCarousel: React.FC<Props> = ({ quotes }) => {
  return (
    <Container>
      <SwiperWrapper>
        <Swiper
          modules={[Autoplay, Navigation, Mousewheel]}
          slidesPerView={1}
          autoplay={{ delay: 5000 }}
          loop={true}
        //   cssMode
          touchEventsTarget="wrapper"
          navigation={true}
        >
          {quotes.map((quote, index) => (
            <SwiperSlide key={index}>
              <Quote>{quote.quote}</Quote>
              <Author>{quote.author}</Author>
            </SwiperSlide>
          ))}
        </Swiper>
      </SwiperWrapper>
    </Container>
  );
}
export default QuoteCarousel;