import React from 'react'
import Layout from '../components/Layout'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, {TableCellProps} from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter'
import * as TypographyWrappers from '../components/Wrapper/PagesTypographyWrappers'
import { MDXProvider } from "@mdx-js/react";
import { Helmet } from "react-helmet";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage} from "gatsby-plugin-image"
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {makeStyles, createStyles} from '@mui/styles'
import { styled } from '@mui/material/styles';
import Hidden from '@mui/material/Hidden'
import BreadCrumb from '../components/BreadCrumb'
import QuoteCarousel from '../components/MDXComponent/LocalComponent/QuoteCarousel';
import BrandProfileComponent from '../components/MDXComponent/LocalComponent/BrandProfileComponent'
import ImageSliderComponent from '../components/MDXComponent/LocalComponent/ImageSliderComponent'


const useStyles = makeStyles((theme:any) => createStyles({
    bannerTextContainer: {
        [theme.breakpoints.down('sm')]: {
          textAlign: "center"
        }
      }
}))


export const headingToHref = (heading: string) =>
  "#" +
  heading
    .toLowerCase()
    .replace(/ /g, "-")
    .replace(/[.,?!/:()]/g, "");

const TableCellWithDefaults = React.forwardRef(function TableCellWithDefaults(
    props: TableCellProps
    ) {
    return <TableCell {...props} align={props.align || "left"} />;
    });

const Article = styled(Grid)<{component: React.ElementType<any>}>(({theme}) => ({
  padding: "80px 160px 20px 80px",
  [theme.breakpoints.down("sm")]: {
    padding: "20px"
  },
}))

      

export default function PageLocal(props:any, location: Location) {
    const classes = useStyles()
    const {frontmatter} = props.pageContext
    const data = useStaticQuery<GatsbyTypes.DefaultImageQuery>(graphql`
    query DefaultImage {
      post: mdx {
        childNavigation {
          slug
          title
        }
      }
    }
    `)
    
    const {post} = data

    const breadcrumbsArray = React.useMemo(() => {
      let crumbArray = [post.childNavigation] as Array <
      Pick<GatsbyTypes.Navigation, "slug" | "title" | "navigationParent">
      >;
      while(crumbArray[crumbArray.length - 1].navigationParent)
        crumbArray.push(crumbArray[crumbArray.length - 1].navigationParent);
      return crumbArray.slice(1).reverse();

    },[post.childNavigation])


    return (
        <Layout location={props.location}>
            <Helmet>
                <title>{`SEM Wiki - ${frontmatter.front_matter_title}`}</title>
            </Helmet>
            <Grid
            item
            // container
            flexDirection="row"
            // justifyContent="space-evenly"
            style={{width: '100%' }}
            >
             <Article item component="article">
                    <BreadCrumb title={frontmatter.front_matter_title} breadcrumbsArray={breadcrumbsArray} />
                    {/* <Typography variant="h4" color="primary" gutterBottom>
                        {frontmatter.title}
                    </Typography> */}
                    <MDXProvider
                        components={{
                        // Map HTML element tag to React component
                        h1: TypographyWrappers.H1,
                        h2: TypographyWrappers.H2,
                        p: TypographyWrappers.Paragraph,
                        table: TypographyWrappers.CustomTable,
                        tr: TypographyWrappers.CustomTableRow,
                        th: TableCellWithDefaults,
                        td: TableCellWithDefaults,
                        thead: TypographyWrappers.CustomTableHeader,
                        tbody: TableBody,
                        ul: TypographyWrappers.CustomUnorderList,
                        ol: TypographyWrappers.CustomOrderList,
                        li: TypographyWrappers.CustomListItem,
                        img: TypographyWrappers.Image,
                        QuoteCarousel,
                        BrandProfileComponent,
                        ImageSliderComponent
                        }}
                    >
                        {props.children}
                    </MDXProvider>
                </Article>   
            </Grid>
        </Layout>
    )
}
