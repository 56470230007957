import React from "react";
import { styled } from "@mui/material/styles";
import { Box, Grid, Typography, Link } from "@mui/material";

interface BrandProfileContents {
    image: string;
    label: string;
    description: string;
    link: string;
}

interface BrandProfileProps {
    brandProfiles: BrandProfileContents[];
}

const Container = styled("div")({
    position: "relative",
});

const BrandProfileComponent: React.FC<BrandProfileProps> = ({ brandProfiles }) => {
    

    return (
        <Container sx={{ marginTop: '18px' }}>
            <Grid display='flex' flexDirection='row' justifyContent='flex-start' width="100%" flexWrap='wrap' gap='12px'>
                {brandProfiles.map((brandProfile, index) => {
                    const { image, label, description, link } = brandProfile
                    return (
                        <Grid display='flex' flexDirection='column' key={index} justifyContent='flex-start' width='150.4px'>
                        <img  src={image} alt={`${index}`} style={{ borderRadius: '7px', borderColor: '#999999', borderStyle: 'solid', width: '146px', height: '146px'}} />
                        <Box display='flex' flexDirection='column' justifyContent='center' padding='8px'>
                            <Typography sx={{ fontWeight: 700, color: "#666666", fontSize: '14px' }}>{label}</Typography>
                            <Typography sx={{ fontWeight: 400, color: "#666666", fontSize: '12px' }}>{description}</Typography>
                            <Link href={link} sx={{ fontWeight: 400, color: "#008200", fontSize: '12px' }}>Link here</Link>
                        </Box>
                    </Grid>
                    )
                })}
            </Grid>
        </Container>
    )

}

export default BrandProfileComponent;
