import React from "react";
import { styled } from "@mui/material/styles";
import { Box, Grid, Typography, Link } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';

interface ImageContents {
    source: string;
    description: string;
}

interface ImageSliderProps {
    images: ImageContents[];
}

const Container = styled("div")({
    position: "relative",
});

const ImageSliderComponent: React.FC<ImageSliderProps> = ({ images }) => {

    return (
        <Container sx={{ marginTop: '18px' }}>
            <Box>
                <Swiper
                    slidesPerView={1}
                    autoplay={{ delay: 5000 }}
                    loop={true}
                >
                    {images.map((image, index)=>(
                        <SwiperSlide key={index}>
                            <Grid display='flex' flexDirection='column' width='614px'>
                                <img width='614px' height='400px' src={image.source} style={{ borderRadius: '7px' }}/>
                                <Typography padding='8px' style={{fontWeight: '400px', fontSize: '14px', color: '#666666'}}>{image.description}</Typography>
                            </Grid>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </Box>
        </Container>
    )

}

export default ImageSliderComponent;
