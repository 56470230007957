import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/app/src/templates/PageLocal.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "how-to-contribute"
    }}>{`How to Contribute?`}</h1>
    <p>{`A Modern Documentation (docs-as-code) strategy looks to enhance and simplify the process of collaboratively creating and publishing content to a broad community. Modern Documentation is an approach where content creation is done in collaboration with a community built on a lightweight approach supported by an open-by-default architecture. The patterns leveraged can be followed by non-technical staff and used to provide an assortment of different content. ​`}</p>
    <p>{`Modern Documentation is when you treat documentation as you would treat code in an source code manner. You follow the same workflows that you would use with code when creating documentation, including source management, feature branching, and pull requests.​`}</p>
    <p>{`At Heineken we recognize Modern Documentation as an emerging approach to generating content, and we are looking to disrupt the current ways of generating and distributing knowledge. All members within a inner and outer circle should be invited to work together on creating and curating content that helps serve their intended audience.`}</p>
    <h2 {...{
      "id": "contributors"
    }}>{`Contributors`}</h2>
    <p>{`SEM Wiki is an inner-source project. This means the entire organization is invited to contribute either with the documentation content or with the source code itself.
We do however require every contributor to follow certain standards to ensure the SEM Wiki project is kept error and bug free, compliant with ethic and security standards and the overall quality is preserved.`}</p>
    <h2 {...{
      "id": "maintainers"
    }}>{`Maintainers`}</h2>
    <p>{`Heineken SEM Team is the original author and maintainer of SEM Wiki. This team will do periodical code and content reviews. We highly encourage everyone willing to contribute to get in touch with us to ask for instructions or help.`}</p>
    <h2 {...{
      "id": "our-standards"
    }}>{`Our standards`}</h2>
    <p>{`In order to bring the most to the community and to make SEM Wiki the tool for everyone to use, please follow these rules:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><em parentName="p">{`Write clear and explanatory content:`}</em>{` SEM Wiki is a work tool. This means that the reader should spend as little time as possible trying to understand what the creator has to say. Write only what is necessary and nothing more or less. `}<em parentName="p">{`Please, make at least 1 extra person evaluate your content before you publish it.`}</em></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><em parentName="p">{`Use professional language:`}</em>{` Use language that is appropriate to our work environment. We will not tolerate any:`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Ethnic or racial slurs`}</li>
          <li parentName="ul">{`Sexualized language`}</li>
          <li parentName="ul">{`Insults`}</li>
          <li parentName="ul">{`Violent threats`}</li>
          <li parentName="ul">{`Political expressions`}</li>
          <li parentName="ul">{`Discriminatory jokes`}</li>
        </ul>
        <p parentName="li">{`We also recommend to write in a highly professional manner without the use of slang, etc.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><em parentName="p">{`Be respectful to others and to others' contributions:`}</em>{` If you do disagree with others or their contributions, make sure you first let them know privately. This is a community and we encourage communication. If you think some content is incorrect, again please contact the author of said content. However, if you do not manage to resolve the issue, please contact the SEM Team and we will handle the issue.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><em parentName="p">{`Do not reveal any personal or client's details:`}</em>{` It is highly unacceptable to reveal any personal details about your colleagues or even unrelated people. Please, do not reveal any of your or others' client details too, including the name of the client. If you do want to show an example, consider changing the data and/or censoring it.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><em parentName="p">{`Be a part of the community:`}</em>{` Communicate with others and be always prepared to help.`}</p>
      </li>
    </ul>
    <h2 {...{
      "id": "getting-started"
    }}>{`Getting started`}</h2>
    <p>{`To get started, check out our `}<a parentName="p" {...{
        "href": "contribute/repositories/"
      }}>{`repository guide`}</a>{` follow on from there to `}<a parentName="p" {...{
        "href": "contribute/markdown/"
      }}>{`Markdown tutorial`}</a>{`. Please, follow both tutorials thoroughly as it is essential to understand them in order to contribute working and quality content.`}</p>
    <h2 {...{
      "id": "contact-us"
    }}>{`Contact Us`}</h2>
    <p>{`If you have any further questions or ideas, please contact the `}<a parentName="p" {...{
        "href": "mailto:joshua.a.esteves@accenture.com"
      }}>{`SEM Team`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      